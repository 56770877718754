import {
	SAVE_PROFILE_PICTURE as PUT,
	DELETE_PROFILE_PICTURE as DELETE,
	GET_PROFILE_PICTURE as GET,
	PROFILE_INFO,
	UPDATE_PROFILE,
	ACCOUNT_INFORMATION,
	GET_CUSTOMERS_PROFILE_ACCOUNT_INFORMATION,
	UPDATE_ACCOUNT,
	SUSPEND_ACCOUNT,
	RESIGN_ACCOUNT,
	TERMINATE_ACCOUNT,
	UPDATE_PERSONAL_INFO,
	GET_SYSTEM_CREDENTIALS,
	UPDATE_SYSTEM_CREDENTIALS,
	DUPLICATED_PROFILE_INFO, REACTIVATE_ACCOUNT,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class Profile {
	constructor() {
		this.data = new Req();
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getProfilePhoto(id, size) {
		const { method, endpoint } = GET;
		return this.data[method](endpoint(id, size)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateProfilePicture(id, payload) {
		const { method, endpoint } = PUT;

		this.options.data = payload;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint(id);
		this.options.method = method;

		return this.data.postFormData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteProfilePicture(id) {
		const { method, endpoint } = DELETE;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getProfile(id) {
		const { method, endpoint } = PROFILE_INFO;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateProfile(id, payload) {
		if (typeof payload.step === 'undefined') {
			payload.step = 'update';
		}
		const { method, endpoint } = UPDATE_PROFILE;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getInformation(id) {
		const { method, endpoint } = ACCOUNT_INFORMATION;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCustomerInformation(id) {
		const { method, endpoint } = GET_CUSTOMERS_PROFILE_ACCOUNT_INFORMATION;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAccount(id, payload) {
		const { method, endpoint } = UPDATE_ACCOUNT;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updatePersonalInfo(id, payload) {
		const { method, endpoint } = UPDATE_PERSONAL_INFO;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	suspendAccount(id, payload) {
		const { method, endpoint } = SUSPEND_ACCOUNT;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	resignAccount(id, payload) {
		const { method, endpoint } = RESIGN_ACCOUNT;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	reactivateAccount(id, payload) {
		const { method, endpoint } = REACTIVATE_ACCOUNT;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	terminateAccount(id, payload) {
		const { method, endpoint } = TERMINATE_ACCOUNT;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSystemCredentials(id) {
		const { method, endpoint } = GET_SYSTEM_CREDENTIALS;
		return this.data[method](endpoint(id)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateSystemCredentials(id, payload) {
		const { method, endpoint } = UPDATE_SYSTEM_CREDENTIALS;
		return this.data[method](endpoint(id), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getDuplicatedFieldInfo(id, field) {
		const { method, endpoint } = DUPLICATED_PROFILE_INFO;
		return this.data[method](endpoint(id, field)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default Profile;
